import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Alert } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';

const NoContractAlertContainer = styled.div`
	position: fixed;
	/* TODO: fix z-index */
	z-index: 1004;
	top: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;

	${({ theme }) => theme.mediaqueries.L} {
		left: 192px;
	}
`;

const NoContractAlertWrapper = styled.div`
	margin-top: 12px;
	max-width: 504px;
	pointer-events: auto;
	box-shadow: ${({ theme }) => theme.shadows.medium};
`;

export const NoContractAlert = () => {
	const { t } = useTranslation('shared');
	const { data: agent } = useAgentResource();
	const isVisible = !agent.isContractAccepted && agent.isImpersonation;

	return isVisible ? (
		<NoContractAlertContainer>
			<NoContractAlertWrapper>
				<Alert color="danger">
					<Alert.Paragraph>{t('agencyNotAcceptedContract')}</Alert.Paragraph>
				</Alert>
			</NoContractAlertWrapper>
		</NoContractAlertContainer>
	) : null;
};
