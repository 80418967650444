import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

export const NavListContainer = styled.ul<{ stickyBottom: boolean }>`
	list-style: none;
	padding: 12px 0;
	margin: 0;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	border-top: ${({ stickyBottom, theme }) =>
		stickyBottom && `1px solid ${theme.colors.gray.lighten90}`};

	&:last-of-type {
		border-bottom: 0;
	}
`;

type NavListProps = {
	isAuthorized?: boolean;
	stickyBottom?: boolean;
};

export const NavList = ({
	children,
	isAuthorized = true,
	stickyBottom = false,
	...props
}: PropsWithChildren<NavListProps>) =>
	isAuthorized ? (
		<NavListContainer stickyBottom={stickyBottom} {...props}>
			{children}
		</NavListContainer>
	) : null;
