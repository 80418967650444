import {
	ListQuotableInsuranceTypesRequest,
	ListQuotableInsuranceTypesResponse
} from '@agentero/grpc-clients/grpc/carrier-fe';
import { parseCarrierAppointmentStatus } from '@agentero/models/carrierappointment';
import { parseInsuranceTypeFromProto } from '@agentero/models/opportunity';
import {
	CarrierOAuthStatus,
	RaterInsuranceType,
	parseQuotingModeFromProto
} from '@agentero/models/quote';
import { getCarrierFromProto } from '@agentero/models/shared';
import { handleGrpcResponse } from '@agentero/service-errors';

import { carrierFrontendService } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';

export const fetchQuotableInsuranceTypes = ({ token, userId }: AgenteroUserData) => {
	return new Promise<RaterInsuranceType[]>((resolve, reject) => {
		const request = new ListQuotableInsuranceTypesRequest();

		carrierFrontendService.listQuotableInsuranceTypes(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				const quotableInsuranceTypes = parseQuotableInsuranceTypes(
					response.toObject().quotableInsuranceTypesList
				);
				resolve(quotableInsuranceTypes);
			}
		);
	});
};

const parseQuotableInsuranceTypes = (
	quotableInsuranceTypes: ListQuotableInsuranceTypesResponse.QuotableInsuranceType.AsObject[]
): RaterInsuranceType[] =>
	quotableInsuranceTypes.map(({ insuranceType, quotableCarriersList }) => ({
		insuranceType: parseInsuranceTypeFromProto(insuranceType),
		quotableCarriers: quotableCarriersList.map(quotableCarrier => {
			let authStates = carrierOAuthStatusMapping[quotableCarrier.authenticationStatus];

			if (
				authStates === CarrierOAuthStatus.AdminNotAuthenticated &&
				quotableCarrier.authenticationBy ===
					ListQuotableInsuranceTypesResponse.AuthenticationBy.AUTHENTICATION_BY_USER
			) {
				authStates = CarrierOAuthStatus.UserNotAuthenticated;
			}

			return {
				carrier: getCarrierFromProto(quotableCarrier.carrier),
				status: parseCarrierAppointmentStatus(quotableCarrier.appointmentStatus),
				OAuthStatus: authStates,
				exceptionalStates: quotableCarrier.exceptionalQuotableStatesList,
				quotingMode: parseQuotingModeFromProto(quotableCarrier.quotingMode),
				isBindingWithAgentero: quotableCarrier.isBindingWithAgentero
			};
		})
	}));

const carrierOAuthStatusMapping: {
	[key in ListQuotableInsuranceTypesResponse.AuthenticationStatus]: CarrierOAuthStatus;
} = {
	[ListQuotableInsuranceTypesResponse.AuthenticationStatus.UNDEFINED]: CarrierOAuthStatus.Undefined,
	[ListQuotableInsuranceTypesResponse.AuthenticationStatus.NOT_REQUIRED]:
		CarrierOAuthStatus.NotRequired,
	[ListQuotableInsuranceTypesResponse.AuthenticationStatus.AUTHENTICATED]:
		CarrierOAuthStatus.Authenticated,
	[ListQuotableInsuranceTypesResponse.AuthenticationStatus.NOT_AUTHENTICATED]:
		CarrierOAuthStatus.AdminNotAuthenticated
};
