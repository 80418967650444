import { NotFoundError } from 'packages/components/error/NotFoundError';
import { Content, Main } from 'packages/layout';

export const Error404 = () => (
	<Main>
		<Content variant="detail">
			<Content.Box>
				<NotFoundError />
			</Content.Box>
		</Content>
	</Main>
);
