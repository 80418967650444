import { FC, PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { IconClose, LogoAgentero } from '@agentero/icons';
import { HStack, styled } from '@agentero/styles/jsx';
import { Button } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { useStopImpersonation } from 'packages/services/mutate/agent-fe/agent-stop-impersonation/useStopImpersonation';
import { agentsRoute } from 'routes';

type FullPageProps = {
	/**
	 * @deprecated It'll be removed when the new layout is fully implemented
	 */
	legacyBehavior?: boolean;
	'data-cy'?: string;
};

type FullPageHeaderProps = {
	onClose?: () => void;
};

type FullPageComponents = {
	Header: FC<PropsWithChildren<FullPageHeaderProps>>;
	Body: FC<PropsWithChildren>;
};

const FullPageRoot = styled('div', {
	base: {},
	variants: {
		legacyBehavior: {
			true: { flex: 1, minHeight: '0' }
		}
	}
});

const FullPageHeaderRoot = styled('header', {
	base: {
		position: 'sticky',
		zIndex: 2,
		top: 0,
		display: 'flex',
		gap: '24',
		padding: '24',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: 'white'
	}
});

const FullPageHeader = ({ onClose }: PropsWithChildren<FullPageHeaderProps>) => {
	const {
		data: { isImpersonation }
	} = useAgentResource();

	const { t } = useTranslation('nav');

	const { stopImpersonation } = useStopImpersonation({
		onError: () => toast.error('Error stopping impersonation an agent, try again later'),
		onSuccess: () => {
			window.location.href = agentsRoute;
		}
	});

	return (
		<FullPageHeaderRoot>
			<LogoAgentero />
			<HStack>
				{isImpersonation && (
					<Button variant="ghost" onClick={stopImpersonation}>
						{t('backToAdmin')}
					</Button>
				)}
				{onClose && (
					<Button size="lg" variant="ghost" onClick={onClose}>
						<IconClose />
					</Button>
				)}
			</HStack>
		</FullPageHeaderRoot>
	);
};

const FullPageBody = styled('div', {
	base: {
		position: 'relative',
		zIndex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '24'
	}
});

export const FullPage: FC<PropsWithChildren<FullPageProps>> & FullPageComponents = ({
	legacyBehavior = false,
	children,
	...props
}) => (
	<FullPageRoot legacyBehavior={legacyBehavior} data-cy={props['data-cy']}>
		{children}
	</FullPageRoot>
);

FullPage.Header = FullPageHeader;
FullPage.Body = FullPageBody;
