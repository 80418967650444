import { useTranslation } from 'react-i18next';

import { Button, ButtonAsLink } from '@agentero/ui';

import { raterRoute } from 'routes';

type GetQuotesButtonProps = Omit<ButtonAsLink, 'as' | 'href'>;

export const GetQuotesButton = (props: GetQuotesButtonProps) => {
	const { t } = useTranslation(['marketAccess', 'rater', 'shared']);

	return (
		<Button as="link" href={raterRoute} {...props}>
			{t('rater:getQuotes')}
		</Button>
	);
};
