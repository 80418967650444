import { PropsWithChildren } from 'react';

import { Flex, Stack } from '@agentero/styles/jsx';
import { Text, TextAlignType } from '@agentero/ui';

export const LandingContent = ({ children }: PropsWithChildren) => {
	return <Stack gap="56">{children}</Stack>;
};

LandingContent.Section = ({ children }: PropsWithChildren) => {
	return <Stack gap="24">{children}</Stack>;
};

type LandingContentTextProps = {
	align?: TextAlignType;
};

LandingContent.Text = ({ children, align }: PropsWithChildren<LandingContentTextProps>) => {
	return (
		<Stack maxWidth="500">
			<Text align={align}>{children}</Text>
		</Stack>
	);
};

LandingContent.IconCardContainer = ({ children }: PropsWithChildren) => {
	return (
		<Flex alignItems="center" justifyContent="center" height="32px">
			{children}
		</Flex>
	);
};
