import styled from '@emotion/styled';

export const NavBody = styled.div`
	flex: 1;
	min-height: 0;
	padding: 12px 16px 0;
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
`;
