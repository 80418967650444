import { FC, PropsWithChildren } from 'react';

import styled from '@emotion/styled';

import { ContentTabs, TabsNav } from '@agentero/components';

type ContentProps = {
	variant?: 'detail' | 'default'; // Deprecated. It has no effect.
	className?: string;
};

type ContentComponents = {
	Wrapper?: FC<PropsWithChildren<ContentWrapperProps>>;
	Box?: FC<PropsWithChildren>;
};

type ContentWrapperSize = 'sm' | 'md' | 'lg';

type ContentWrapperProps = {
	size?: ContentWrapperSize;
};

const ContentContainer = styled.div<ContentProps>`
	flex: 1;
	display: flex;
	flex-direction: column;
	min-height: 0;
	overflow: hidden;
	overflow-y: auto;
	padding: 16px 16px 0 16px;
	border-top: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	scroll-behavior: smooth;

	&:after {
		content: '';
		display: block;
		min-height: 16px;
		width: 100%;
	}

	${TabsNav} {
		margin-top: -16px;
		background: ${({ theme }) => theme.colors.white};
	}

	&:has(${ContentTabs}) {
		border-top: 0;
	}

	/* Needed in case Tabs component from @agentero/ui is used */
	&:has(> div > [role='tablist']) {
		border-top: 0;
		padding: 0;
	}
`;

const contentWrapperSizeMapping: Record<ContentWrapperSize, string> = {
	['sm']: '40rem',
	['md']: '55.25rem',
	['lg']: '76rem'
};

const ContentWrapper = styled.div<ContentWrapperProps>`
	flex: 1;
	max-width: ${({ size = 'lg' }) => contentWrapperSizeMapping[size]};
	width: 100%;
	margin: 0 auto;
`;

const ContentBox = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 16px;
	background: ${({ theme }) => theme.colors.white};
`;

export const Content: FC<PropsWithChildren<ContentProps>> & ContentComponents = ({
	variant,
	className,
	children
}) => (
	<ContentContainer variant={variant} className={className}>
		{children}
	</ContentContainer>
);

Content.Wrapper = ContentWrapper;
Content.Box = ContentBox;
