import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

Container.defaultProps = {
	//@ts-ignore
	'data-role': 'container'
};
