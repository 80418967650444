import { UseQueryOptions } from '@tanstack/react-query';

import { RaterInsuranceType } from '@agentero/models/quote';

import { queryClient } from 'packages/services/QueryCache';

import {
	AgenteroUserData,
	GrpcPortalRequest,
	fetchAgQuery,
	prefetchAgQuery,
	useAgNoSuspenseQuery,
	useAgQuery
} from '../../useAgQuery';
import { fetchQuotableInsuranceTypes } from './fetchQuotableInsuranceTypes';

export const quotableInsuranceTypesKey = 'quotable-insurance-types';

const quotableInsuranceTypesConfig: GrpcPortalRequest<{}, RaterInsuranceType[]> = {
	request: fetchQuotableInsuranceTypes,
	key: ({ userId }) => [quotableInsuranceTypesKey, userId]
};

export const useQuotableInsuranceTypesQuery = (config?: UseQueryOptions<RaterInsuranceType[]>) =>
	useAgQuery(quotableInsuranceTypesConfig, {}, config);

export const useQuotableInsuranceTypesNoSuspenseQuery = (
	config?: UseQueryOptions<RaterInsuranceType[]>
) => useAgNoSuspenseQuery(quotableInsuranceTypesConfig, {}, config);

export const fetchQuotableInsuranceTypesQuery = ({ userId, token }: AgenteroUserData) =>
	fetchAgQuery(quotableInsuranceTypesConfig, { userId, token });

export const prefetchQuotableInsuranceTypesQuery = ({ userId, token }: AgenteroUserData) =>
	prefetchAgQuery(quotableInsuranceTypesConfig, { userId, token });

export const invalidateQuotableInsuranceTypesResource = (userId: string) =>
	queryClient.invalidateQueries({ queryKey: [quotableInsuranceTypesKey, userId] });
