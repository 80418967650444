import { QuotingMode as QuotingModeProto } from '@agentero/grpc-clients/grpc/shared/carrier';

export enum QuotingMode {
	None,
	API,
	Rater
}

const quotingModeFromProtoMapping: { [key in QuotingModeProto]: QuotingMode } = {
	[QuotingModeProto.NONE]: QuotingMode.None,
	[QuotingModeProto.API]: QuotingMode.API,
	[QuotingModeProto.RATER]: QuotingMode.Rater
};

export const parseQuotingModeFromProto = (quotingMode: QuotingModeProto): QuotingMode =>
	quotingModeFromProtoMapping[quotingMode];

const quotingModeFToProtoMapping: { [key in QuotingMode]: QuotingModeProto } = {
	[QuotingMode.None]: QuotingModeProto.NONE,
	[QuotingMode.API]: QuotingModeProto.API,
	[QuotingMode.Rater]: QuotingModeProto.RATER
};

export const parseQuotingModeToProto = (quotingMode: QuotingMode): QuotingModeProto =>
	quotingModeFToProtoMapping[quotingMode];
