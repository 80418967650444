import styled from '@emotion/styled';

import { Alert } from '@agentero/components';

export const AuthBox = styled.div`
	width: 100%;
	margin: auto 0;
	padding: 32px 16px;
	background: ${({ theme }) => theme.colors.white};
	text-align: center;
	box-shadow: ${({ theme }) => theme.shadows.hard};

	${({ theme }) => theme.mediaqueries.S} {
		padding: 40px 32px;
	}

	${({ theme }) => theme.mediaqueries.M} {
		padding: 64px;
	}
`;

export const AuthBoxAlert = styled(Alert)`
	text-align: left;
	margin: -16px 0 32px;

	${({ theme }) => theme.mediaqueries.S} {
		margin: -16px -8px 32px;
	}

	${({ theme }) => theme.mediaqueries.M} {
		margin: -32px -32px 32px;
	}
`;
