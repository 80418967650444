import styled from '@emotion/styled';

export const AuthBg = styled.div`
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: url('/portal-ui-content/assets/images/auth/skyline.svg') no-repeat center bottom,
		${({ theme }) => theme.gradients.main};
`;
