import styled from '@emotion/styled';

import { FormActions } from '@agentero/components';

export const AuthFormContainer = styled.div`
	text-align: left;
	margin: 0 auto;

	${({ theme }) => theme.mediaqueries.S} {
		max-width: 338px;
	}
`;

export const AuthFormActions = styled(FormActions)`
	margin-top: 8px;

	${({ theme }) => theme.mediaqueries.S} {
		justify-content: center;
	}
`;
