import { PropsWithChildren, useMemo } from 'react';

import { useGetInitialPropsError } from 'packages/contexts/GetInitialPropsErrorContext';

import { Error404 } from './Error404';
import { Error500 } from './Error500';

export const AgentGetInitialPropsError = ({ children }: PropsWithChildren) => {
	// NOTE: This mapping has been move within the function to mitigate:
	// ReferenceError: Cannot access 'Error500' before initialization
	const errorMapping = useMemo(
		() => ({
			3: Error404,
			5: Error404,
			7: Error404,
			13: Error500,
			401: Error404,
			403: Error404,
			404: Error404,
			500: Error500
		}),
		[]
	);

	const { error } = useGetInitialPropsError();
	const Error = errorMapping[error?.statusError] || Error404;

	return error ? <Error /> : <>{children}</>;
};
