import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { EmptyState } from '@agentero/components';
import { IconWarning } from '@agentero/icons';
import { Button } from '@agentero/ui';

type InternalServerErrorProps = {
	onReload: () => void;
};

export const InternalServerError = ({ onReload }: InternalServerErrorProps) => {
	const { t } = useTranslation('error');

	return (
		<EmptyState>
			<EmptyState.Icon>
				<IconWarning />
			</EmptyState.Icon>
			<EmptyState.Title>{t('internalServer.title')}</EmptyState.Title>
			<EmptyState.Body>
				{t('internalServer.body.start')}{' '}
				<Link href={`mailto:${t('internalServer.body.email')}`}>
					<b>{t('internalServer.body.email')}</b>
				</Link>{' '}
				{t('internalServer.body.end')}
			</EmptyState.Body>
			<EmptyState.Actions>
				<Button onClick={onReload}>{t('internalServer.actions.primary')}</Button>
			</EmptyState.Actions>
		</EmptyState>
	);
};
