import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

import { NavOpenButton } from 'packages/components/nav/NavOpenButton';

const HeaderContainer = styled.header`
	position: relative;
	display: flex;
	padding: 0 16px;
	padding-left: 48px;
	min-height: 60px;
	height: 60px;

	${({ theme }) => theme.mediaqueries.L} {
		padding-left: 16px;
	}
`;

export const HeaderTitle = styled.h1`
	margin: 0;
	min-width: 0;
	${({ theme }) => theme.fontWeight.medium};
	${({ theme }) => theme.textSettings.M};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const HeaderTitleSubtext = styled.small`
	display: block;
	${({ theme }) => theme.fontWeight.regular};
	${({ theme }) => theme.textSettings.S};
	color: ${({ theme }) => theme.colors.gray.lighten40};
	text-transform: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: -4px 0 -2px 0;
`;

export const HeaderLabel = styled.div`
	position: relative;
	padding-left: 10px;
	color: ${({ theme }) => theme.colors.gray.lighten40};
	text-transform: uppercase;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 2px;
		height: 12px;
		margin-top: -6px;
		background: ${({ theme }) => theme.colors.gray.lighten40};
	}
`;

export const HeaderMain = styled.div`
	min-width: 0;
	display: flex;
	align-items: center;
	overflow: hidden;
`;

export const HeaderItem = styled.div`
	display: inline-flex;
	align-items: center;
	margin-left: 16px;

	/* TODO: create a styled component to wrap the back button to avoid this */
	&:first-of-type:not(${HeaderTitle} + div) {
		margin-left: 0;
	}

	+ ${HeaderTitle} {
		margin-left: 8px;
	}
`;

export const HeaderActions = styled.div<{ align?: 'left' | 'right' }>`
	display: inline-flex;
	align-items: center;
	margin-left: ${({ align = 'left' }) => (align === 'left' ? '0' : 'auto')};
	padding-left: 16px;

	${HeaderItem} {
		margin-left: 8px;

		${({ theme }) => theme.mediaqueries.M} {
			margin-left: 16px;
		}
	}
`;

export const Header = ({ children }: PropsWithChildren) => (
	<HeaderContainer data-cy="header">
		<NavOpenButton />
		{children}
	</HeaderContainer>
);
