import React from 'react';

import { useTranslation } from 'react-i18next';

import {
	IconAppointments,
	IconAttachMoney,
	IconDashboard,
	IconDescription,
	IconList,
	IconPersonAdd,
	IconRequestQuote
} from '@agentero/icons';
import { Role, isSuperAdmin } from '@agentero/models/agent';
import { FeatureFlagKey } from '@agentero/models/featureflags';
import { HStack } from '@agentero/styles/jsx';
import { Tag } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { useFeatureFlagsQuery } from 'packages/services/fetch/feature-flags-fe/useFeatureFlagsQuery';
import {
	adminAppointmentRequestsRoute,
	adminCarriersRoute,
	adminCommissionsRoute,
	applicationsRoute,
	bindersRoute,
	carriersRoute,
	commissionsRoute,
	dashboardRoute,
	leadsRoute,
	quotesRoute
} from 'routes';

import { NavItem } from './shared/NavItem';
import { NavLink } from './shared/NavLink';
import { NavList } from './shared/NavList';

export const AggregatorNavList = () => {
	const isApplicationsPage = useFeatureFlagsQuery()[FeatureFlagKey.ApplicationsPage];
	const isLeadPage = useFeatureFlagsQuery()[FeatureFlagKey.LeadPage];

	const { t } = useTranslation('nav');
	const {
		data: { role }
	} = useAgentResource();
	const isSuperAdminValue = isSuperAdmin(role);

	const isCommissionsAuthorized = role === Role.AgencyAdmin || role === Role.SuperAdmin;
	const isDashboardAuthorized = role !== Role.SuperAdmin;
	const isQuotesAuthorized = role !== Role.SuperAdmin;

	return (
		<NavList data-cy="nav-list-aggregator">
			<NavItem isAuthorized={isDashboardAuthorized}>
				<NavLink href={dashboardRoute} Icon={IconDashboard}>
					{t('dashboard')}
				</NavLink>
			</NavItem>

			<NavItem>
				<NavLink
					href={isSuperAdminValue ? adminCarriersRoute : carriersRoute}
					Icon={IconAppointments}>
					{t('carriers')}
				</NavLink>
			</NavItem>

			<NavItem isAuthorized={isQuotesAuthorized}>
				{isApplicationsPage ? (
					<NavLink href={applicationsRoute} Icon={IconList}>
						{t('applications')}
					</NavLink>
				) : (
					<NavLink href={quotesRoute} Icon={IconList}>
						{t('quotes')}
					</NavLink>
				)}
			</NavItem>

			<NavItem>
				<NavLink href={bindersRoute} Icon={IconRequestQuote}>
					{t('binders')}
				</NavLink>
			</NavItem>

			{isSuperAdminValue && (
				<NavItem>
					<NavLink href={adminAppointmentRequestsRoute} Icon={IconDescription}>
						{t('appointmentRequests')}
					</NavLink>
				</NavItem>
			)}

			{isLeadPage && (
				<NavItem isAuthorized={isQuotesAuthorized}>
					<NavLink href={leadsRoute} Icon={IconPersonAdd}>
						<HStack justifyContent="space-between" flex={1}>
							{t('leads')} <Tag color="green">New</Tag>
						</HStack>
					</NavLink>
				</NavItem>
			)}

			<NavItem isAuthorized={isCommissionsAuthorized}>
				<NavLink
					href={isSuperAdminValue ? adminCommissionsRoute : commissionsRoute}
					Icon={IconAttachMoney}>
					{t('commissions')}
				</NavLink>
			</NavItem>
		</NavList>
	);
};
