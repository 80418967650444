import { CarrierAppointmentStatus } from '../../carrierappointment/CarrierAppointmentStatus';
import { Carrier } from '../../shared';
import { QuotingMode } from './QuotingMode';

export enum CarrierOAuthStatus {
	Undefined,
	NotRequired,
	Authenticated,
	UserNotAuthenticated,
	AdminNotAuthenticated
}

export type QuotableCarrier = {
	carrier: Carrier;
	status: CarrierAppointmentStatus;
	OAuthStatus: CarrierOAuthStatus;
	exceptionalStates: string[];
	quotingMode: QuotingMode;
	isBindingWithAgentero: boolean;
};

export const isCarrierAppointedAndAuth = (quotableCarrier: QuotableCarrier) => {
	return (
		quotableCarrier.status === CarrierAppointmentStatus.Appointed &&
		![CarrierOAuthStatus.UserNotAuthenticated, CarrierOAuthStatus.AdminNotAuthenticated].includes(
			quotableCarrier.OAuthStatus
		)
	);
};

export const isAnyCarrierAppointed = (quotableCarriers: QuotableCarrier[]) => {
	return quotableCarriers.some(
		quotableCarrier => quotableCarrier.status === CarrierAppointmentStatus.Appointed
	);
};

export const isCarrierAppointedAndUserNoAuth = (quotableCarrier: QuotableCarrier) => {
	return (
		quotableCarrier.status === CarrierAppointmentStatus.Appointed &&
		quotableCarrier.OAuthStatus === CarrierOAuthStatus.UserNotAuthenticated
	);
};

export const isCarrierAppointedAndAdminNoAuth = (quotableCarrier: QuotableCarrier) => {
	return (
		quotableCarrier.status === CarrierAppointmentStatus.Appointed &&
		quotableCarrier.OAuthStatus === CarrierOAuthStatus.AdminNotAuthenticated
	);
};

export const isAnyAppointedAndAuthCarrier = (quotableCarriers: QuotableCarrier[]) => {
	return quotableCarriers.filter(carrier => isCarrierAppointedAndAuth(carrier)).length > 0;
};

export const getAppointedAndUserNotAuthenticatedCarriers = (
	quotableCarriers: QuotableCarrier[]
) => {
	return quotableCarriers.filter(carrier => isCarrierAppointedAndUserNoAuth(carrier));
};

export const isAnyAppointedAndUserNotAuthenticated = (quotableCarriers: QuotableCarrier[]) => {
	return getAppointedAndUserNotAuthenticatedCarriers(quotableCarriers).length > 0;
};

export const isAnyAppointedAndAdminNotAuthenticated = (quotableCarriers: QuotableCarrier[]) => {
	return quotableCarriers.some(carrier => isCarrierAppointedAndAdminNoAuth(carrier));
};

export const getNotAppointedCarriers = (quotableCarriers: QuotableCarrier[]) => {
	return quotableCarriers.filter(carrier =>
		[
			CarrierAppointmentStatus.Available,
			CarrierAppointmentStatus.AvailableNew,
			CarrierAppointmentStatus.InProgress,
			CarrierAppointmentStatus.Pending
		].includes(carrier.status)
	);
};

export const isAnyBindWithAgenteroCarrier = (quotableCarriers: QuotableCarrier[]) =>
	quotableCarriers.some(({ isBindingWithAgentero }) => isBindingWithAgentero);
