import styled from '@emotion/styled';

import { GridItem } from '@agentero/components';

export const AuthMain = styled.main`
	width: 100%;
	max-width: 432px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;

	${({ theme }) => theme.mediaqueries.M} {
		max-width: 592px;
	}

	${({ theme }) => theme.mediaqueries.L} {
		max-width: 984px;
	}

	${GridItem} {
		&:first-of-type {
			order: 2;

			${({ theme }) => theme.mediaqueries.L} {
				&:first-of-type {
					order: unset;
				}
			}
		}
	}
`;
