import styled from '@emotion/styled';

export const AuthFeatures = styled.div`
	margin-top: 24px;
	color: ${({ theme }) => theme.colors.white};
	fill: ${({ theme }) => theme.colors.white};

	${({ theme }) => theme.mediaqueries.S} {
		margin-top: 32px;
	}

	${({ theme }) => theme.mediaqueries.M} {
		margin-top: 40px;
	}

	${({ theme }) => theme.mediaqueries.L} {
		margin-top: unset;
	}
`;
