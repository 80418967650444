import styled from '@emotion/styled';

export const Main = styled.main`
	flex: 1;
	display: flex;
	flex-direction: column;
	min-height: 0;
`;

Main.defaultProps = {
	role: 'main'
};
