import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { IconArrowBack, IconHelp, IconPowerOff, IconSettings } from '@agentero/icons';
import { Role } from '@agentero/models/agent';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { useStopImpersonation } from 'packages/services/mutate/agent-fe/agent-stop-impersonation/useStopImpersonation';
import {
	agentsRoute,
	getSettingsAdminDetailsRoutePath,
	getSettingsAgentDetailsRoutePath
} from 'routes';

import { NavBackAgLink } from './shared/NavBackAgLink';
import { NavItem } from './shared/NavItem';
import { NavLink, NavLinkButton } from './shared/NavLink';
import { NavList } from './shared/NavList';

export const NavSettings = () => {
	const { t } = useTranslation('nav');

	const {
		data: { id, role, isImpersonation }
	} = useAgentResource();

	const { stopImpersonation } = useStopImpersonation({
		onError: () => toast.error('Error stopping impersonation an agent, try again later'),
		onSuccess: () => {
			window.location.href = agentsRoute;
		}
	});

	return (
		<NavList stickyBottom>
			<NavItem>
				<NavLink
					href={
						role !== Role.SuperAdmin
							? getSettingsAgentDetailsRoutePath(id)
							: getSettingsAdminDetailsRoutePath(id)
					}
					Icon={IconSettings}>
					{t('settings')}
				</NavLink>
			</NavItem>
			<NavItem>
				<NavLink
					href="https://support.agentero.com/knowledge"
					Icon={IconHelp}
					target="_blank"
					rel="noopener noreferrer">
					{t('helpCenter')}
				</NavLink>
			</NavItem>
			<NavItem isAuthorized={isImpersonation}>
				<NavLinkButton onClick={stopImpersonation} Icon={IconArrowBack}>
					{t('backToAdmin')}
				</NavLinkButton>
			</NavItem>
			<NavItem>
				<NavBackAgLink href="/sign_out" Icon={IconPowerOff}>
					{t('logout')}
				</NavBackAgLink>
			</NavItem>
		</NavList>
	);
};
