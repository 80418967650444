import React from 'react';

import styled from '@emotion/styled';
import Link from 'next/link';

import { LogoAgentero } from '@agentero/icons';

const NavLogoContainer = styled.div`
	margin-bottom: 20px;

	svg {
		width: 120px;
		height: 32px;
		vertical-align: top;

		path {
			fill: ${({ theme }) => theme.colors.primary.base};
		}
	}
`;

export const NavLogo = () => {
	return (
		<NavLogoContainer>
			<Link href="/dashboard" passHref>
				<LogoAgentero />
			</Link>
		</NavLogoContainer>
	);
};
