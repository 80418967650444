import styled from '@emotion/styled';

export const NavButton = styled.a<{ isActive?: boolean }>`
	display: flex;
	padding: 4px 16px;
	align-items: center;
	margin: 0 -16px;
	${({ theme }) => theme.textSettings.S};
	color: ${({ theme }) => theme.colors.gray.base};
	text-decoration: none;
	text-align: left;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	outline: none;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
		color: ${({ theme }) => theme.colors.gray.base};
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover,
		&:focus {
			color: ${({ theme }) => theme.colors.gray.base};
			text-decoration: none;
			background: ${({ theme }) => theme.colors.gray.lighten95};

			svg {
				path {
					fill: ${({ theme }) => theme.colors.gray.base};
				}
			}
		}
	}

	&:active,
	&.is-active {
		color: ${({ theme }) => theme.colors.gray.base};
		background: ${({ theme }) => theme.colors.gray.lighten90};

		svg {
			path {
				fill: ${({ theme }) => theme.colors.gray.base};
			}
		}
	}

	&.is-active {
		${({ theme }) => theme.fontWeight.medium}
	}

	&:first-letter {
		text-transform: uppercase;
	}

	svg {
		width: 16px;
		height: 16px;

		path {
			fill: ${({ theme, isActive }) =>
				isActive ? theme.colors.primary.base : ({ theme }) => theme.colors.gray.base};
		}
	}
`;

export const NavButtonIcon = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	margin-right: 12px;

	svg {
		width: 16px;
		height: 16px;
	}
`;
