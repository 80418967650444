import { useRouter } from 'next/router';

export const useIsActiveLink = (href: string) => {
	const { pathname } = useRouter();
	const pathNameSplitted = pathname.split('/');
	const hrefSplitted = href.split('/');

	//FIXME: This is a temporary fix for the admin links
	if (pathNameSplitted[1] === 'admin' && hrefSplitted[1] == 'admin')
		return (
			`/${pathNameSplitted[1]}` === href ||
			(`/${pathNameSplitted[2]}` === `/${hrefSplitted[2]}` &&
				`/${hrefSplitted[2]}` !== '/undefined')
		);

	return (
		`/${pathNameSplitted[1]}` === href ||
		(`/${pathNameSplitted[1]}` === `/${hrefSplitted[1]}` && `/${hrefSplitted[1]}` !== '/undefined')
	);
};
