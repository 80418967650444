import styled from '@emotion/styled';

export const AuthLogoAgentero = styled.div<{ isDesktopHidden?: boolean }>`
	margin-right: auto;
	margin-bottom: 24px;
	display: ${({ isDesktopHidden }) => (!isDesktopHidden ? 'none' : 'block')};

	svg {
		height: 24px;
		vertical-align: top;
		width: auto;

		path {
			fill: ${({ theme }) => theme.colors.white};
		}
	}

	${({ theme }) => theme.mediaqueries.M} {
		margin-bottom: 32px;

		svg {
			height: 40px;
		}
	}

	${({ theme }) => theme.mediaqueries.L} {
		display: ${({ isDesktopHidden }) => (isDesktopHidden ? 'none' : 'block')};
	}
`;
