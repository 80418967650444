import { FC, PropsWithChildren } from 'react';

import { AgentGetInitialPropsError } from 'modules/AgentGetInitialPropsError';
import { Nav } from 'packages/components/Nav';
import { NoContractAlert } from 'packages/components/NoContractAlert';

import { Container } from '../layout/Container';
import { FullPage } from './FullPage';

export const Page: FC<PropsWithChildren> = ({ children, ...props }) => (
	<FullPage legacyBehavior {...props}>
		<Nav />
		<Container>
			<AgentGetInitialPropsError>{children}</AgentGetInitialPropsError>
		</Container>
		<NoContractAlert />
	</FullPage>
);
