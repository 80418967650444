import { InternalServerError } from 'packages/components/error/InternalServerError';
import { Content, Main } from 'packages/layout';

export const Error500 = () => (
	<Main>
		<Content>
			<InternalServerError onReload={() => location.reload()} />
		</Content>
	</Main>
);
