import { useTranslation } from 'react-i18next';

import { EmptyState } from '@agentero/components';
import { IconFindInPage } from '@agentero/icons';
import { Role } from '@agentero/models/agent';
import { Button } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { dashboardRoute } from 'routes';

export const NotFoundError = () => {
	const { t } = useTranslation('error');
	const {
		data: { role }
	} = useAgentResource();

	return (
		<EmptyState>
			<EmptyState.Icon>
				<IconFindInPage />
			</EmptyState.Icon>
			<EmptyState.Title>{t('notFound.title')}</EmptyState.Title>
			<EmptyState.Body>{t('notFound.body')}</EmptyState.Body>
			{role !== Role.SuperAdmin && (
				<EmptyState.Actions>
					<Button as="link" href={dashboardRoute}>
						{t('notFound.actions.primary')}
					</Button>
				</EmptyState.Actions>
			)}
		</EmptyState>
	);
};
