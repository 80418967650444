import { AppointmentStatus } from '@agentero/grpc-clients/grpc/shared/carrier';
import { calculateDaysDiff } from '@agentero/utils';

export enum CarrierAppointmentStatus {
	UndefinedStatus,
	Available,
	AvailableNew,
	Rejected,
	Appointed,
	InProgress,
	AnotherAggregator,
	AnotherAggregatorNew,
	Pending,
	Unavailable,
	NotEligible,
	NonSolicitation,
	Hide,
	Terminated
}

export const isNewStatus = (status: CarrierAppointmentStatus) => {
	return [
		CarrierAppointmentStatus.AvailableNew,
		CarrierAppointmentStatus.AnotherAggregatorNew
	].includes(status);
};

export const isNonAgentero = (status: CarrierAppointmentStatus) =>
	status === CarrierAppointmentStatus.Unavailable;

export const carrierAppointmentMapping: {
	[key in AppointmentStatus]: CarrierAppointmentStatus;
} = {
	[AppointmentStatus.AVAILABLE]: CarrierAppointmentStatus.Available,
	[AppointmentStatus.REJECTED]: CarrierAppointmentStatus.Rejected,
	[AppointmentStatus.APPOINTED]: CarrierAppointmentStatus.Appointed,
	[AppointmentStatus.IN_PROGRESS]: CarrierAppointmentStatus.InProgress,
	[AppointmentStatus.ANOTHER_AGGREGATOR]: CarrierAppointmentStatus.AnotherAggregator,
	[AppointmentStatus.PENDING]: CarrierAppointmentStatus.Pending,
	[AppointmentStatus.UNAVAILABLE]: CarrierAppointmentStatus.Unavailable,
	[AppointmentStatus.NOT_ELIGIBLE]: CarrierAppointmentStatus.NotEligible,
	[AppointmentStatus.NON_SOLICITATION]: CarrierAppointmentStatus.NonSolicitation,
	[AppointmentStatus.HIDE]: CarrierAppointmentStatus.Hide,
	[AppointmentStatus.TERMINATED]: CarrierAppointmentStatus.Terminated,
	[AppointmentStatus.UNDEFINED_STATUS]: CarrierAppointmentStatus.UndefinedStatus
};

export const parseCarrierAppointmentStatus: (
	status: AppointmentStatus,
	releaseDate?: Date
) => CarrierAppointmentStatus = (status, releaseDate) => {
	if (releaseDate) {
		const daysDiff = calculateDaysDiff(releaseDate, new Date());
		if (
			[AppointmentStatus.AVAILABLE, AppointmentStatus.ANOTHER_AGGREGATOR].includes(status) &&
			daysDiff <= 30
		) {
			return status === AppointmentStatus.AVAILABLE
				? CarrierAppointmentStatus.AvailableNew
				: CarrierAppointmentStatus.AnotherAggregatorNew;
		}
	}

	return carrierAppointmentMapping[status];
};

export const isAppointed = (appointmentStatus: CarrierAppointmentStatus) =>
	appointmentStatus === CarrierAppointmentStatus.Appointed;
