import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';

import { NavButton, NavButtonIcon } from './NavButton';
import { useIsActiveLink } from './useIsActiveLink';

type NavBackAgLinkProps = DetailedHTMLProps<
	AnchorHTMLAttributes<HTMLAnchorElement>,
	HTMLAnchorElement
> & {
	href?: string;
	Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
};

export const NavBackAgLink: React.FC<NavBackAgLinkProps> = ({ href, children, Icon, ...props }) => {
	const isActive = useIsActiveLink(href);

	return (
		<NavButton href={href} className={isActive ? 'is-active' : ''} {...props}>
			<NavButtonIcon>
				<Icon />
			</NavButtonIcon>
			{children}
		</NavButton>
	);
};
